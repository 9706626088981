
import CommonMixin from '@/mixins/CommonMixin';
import ToolsMixin from '@/mixins/ToolsMixin';
import ImportResult from '@/components/Common/ResultScreen/ImportResult.vue';
import { handleErrorUI } from '@/util/error';

export default {
  components: { ImportResult },
  mixins: [CommonMixin, ToolsMixin],
  data() {
    return {
      data: {}
    };
  },
  async created() {
    try {
      let key = this.$route.params.id;
      const data = await this.$repo.extension.getUploadResult(key);
      if (data?.status === 400) {
        handleErrorUI(
          data?.message.replace('{{successCount}}', data?.successCount)
        );
        // this.close();
      }
      this.data = data;
    } catch (e) {
      handleErrorUI(e);
    }
  },
  methods: {
    close() {
      this.$router.push({
        name: 'tools:import'
      });
    }
  }
};
